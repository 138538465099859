jQuery( document ).ready( function( $ ) {
	$( '#wpforms-form-226' ).on( 'wpformsAjaxSubmitSuccess', async function( json ) {
		const form = $( json.target );
		const email = form.find( '.js-request-a-quote-email-address-field input' ).val();
		const occupation = form.find( '.js-request-a-quote-role-field select' ).val();
		const country = form.find( '.js-request-a-quote-country-field select' ).val();
		const state = form.find( '.js-request-a-quote-state-field input' ).val();
		const location = `${ country ? country : '' } - ${ state ? state : '' }`;
		dataLayer.push( {
			event: 'form_complete',
			form_name: 'request a quote',
			lead_email: email ? await hash( email ) : '',
			lead_occupation: occupation ? occupation : '',
			lead_location: location,
		} );
	} );

	async function hash( string ) {
		const utf8 = new TextEncoder().encode( string );
		const hashBuffer = await crypto.subtle.digest( 'SHA-256', utf8 );
		const hashArray = Array.from( new Uint8Array( hashBuffer ) );
		const hashHex = hashArray
			.map( ( bytes ) => bytes.toString( 16 ).padStart( 2, '0' ) )
			.join( '' );
		return hashHex;
	}
} );
